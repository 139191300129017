// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Imports
// ----------------------------------------------------------------------------
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Libraries
import React from 'react'
// import PropTypes from 'prop-types'

import forEach from 'lodash/forEach'
import join from 'lodash/join'

import compose from 'recompose/compose'
import { connect } from 'react-redux'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Components
import Row from 'antd/lib/row'
import 'antd/lib/row/style/css'

import Col from 'antd/lib/col'
import 'antd/lib/col/style/css'

import { graphql } from 'gatsby'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Locals
import smallKey from '../methods/smallKey'

import Link from '../components/link'
import '../components/link/style.less'

import Breadcrumbs from '../components/breadcrumbs'
import '../components/breadcrumbs/style.less'

import DonateForm from '../components/donate-form'
import '../components/donate-form/style.less'

import StandardPageWrapper from '../components/standard-page-wrapper'
import '../components/standard-page-wrapper/style.less'

import {
  pushObjectIntoShoppingCart,
  popObjectFromShoppingCart,
  pushNotice,
  increaseCountOfObjectInShoppingCart,
  decreaseCountOfObjectInShoppingCart,
  resetShoppingCart,
  setForwardData,
} from '../state/actions'

import website from '../seo/website.json'

// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ Abstractions
// const { Fragment } = React
const pageSchema = {
  title: 'Support us',
  slug: '/support-us',
  abstract: 'Support us',
  breadcrumbs: [
    { title: 'Homepage', slug: '' },
    { title: 'Support us', slug: '/support-us' },
  ],
}
const formRef = React.createRef()

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Images
// ----------------------------------------------------------------------------
export const query = graphql`
  query {
    img1: file(relativePath: { eq: "covers/img1.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: FULL_WIDTH
          placeholder: TRACED_SVG
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

// ----------------------------------------------------------------------------
// ------------------------------------------------------------------ Component
// ----------------------------------------------------------------------------
/** Page */
class Page extends React.Component {
  /** standard constructor */
  constructor(props) {
    super(props)
  }

  /** after mount */
  componentDidMount() {
    const {
      pushObjectIntoShoppingCart,
      shoppingCartState: { totalCountOfObjects },
    } = this.props
    if (totalCountOfObjects === 0) {
      pushObjectIntoShoppingCart({
        title: `Contribution for Aura Network`,
        price: 500,
        routeSlug: website.url,
      })
      console.log('added the first')
    }
  }

  /** standard renderer */
  render() {
    const {
      pushObjectIntoShoppingCart,
      popObjectFromShoppingCart,
      pushNotice,
      shoppingCartState,
      increaseCountOfObjectInShoppingCart,
      decreaseCountOfObjectInShoppingCart,
      resetShoppingCart,
      setForwardData,
    } = this.props
    const {
      objectsList,
      objectsCounts,
      totalCountOfObjects,
      totalPriceOfObjects,
    } = shoppingCartState

    const objectsRequested = []
    forEach(objectsList, (object) => {
      objectsRequested.push(
        `${object.title} (${objectsCounts[object.routeSlug]}${
          objectsCounts[object.routeSlug] > 1 ? ' numbers' : ''
        }, at ₹${object.price}${
          objectsCounts[object.routeSlug] > 1 ? ' each' : ''
        })`
      )
    })
    const objectsRequestedString = join(objectsRequested, '; ')
    const requestId = smallKey()

    const shopConf = {}

    const formConf = {
      macroId: 'AKfycby3Jl6vRaoYs8yq0yuUOna5s3KPCzZF1KR_BDLBU9-TTO6IHX0',
      includeTitle: true,
      includePlaceholders: false,
      includeExtra: true,
      fields: {
        name: {
          title: 'Name',
          extra: 'Required',
        },
        email: {
          title: 'Email',
          extra: 'Required',
        },
        phone: {
          title: 'Phone',
          extra: 'Required',
        },
        comment: {
          extra: 'Optional',
          placeholder:
            "If you'd like you can leave us a comment, review, feedback, or input...",
          rules: [
            {
              validator: (_, value) => {
                return Promise.resolve()
              },
            },
          ],
        },
      },
      trace: {
        success:
          'Please click on next to proceed to the payment gateway. You will be redirected to the Auroville Payment gateway. If your browser or extension detects this as a pop-up, please allow the pop-up.',
      },
      allowReset: false,
      onReset: () => resetShoppingCart(),
      canSubmit: totalCountOfObjects > 0,
      alsoInclude: {
        totalObjects: `${totalCountOfObjects}`,
        totalQuotedPrice: `${totalPriceOfObjects}`,
        requested: objectsRequestedString,
        requestId,
      },
    }

    return (
      <StandardPageWrapper pageSchema={pageSchema} {...this.props}>
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
              { xs: 0, sm: 0, md: 0, lg: 0, xl: 0, xxl: 0 },
            ]}
          >
            <Col xs={24} sm={24} md={16} lg={16} xl={16} xxl={16}>
              <Breadcrumbs breadcrumbs={pageSchema.breadcrumbs} />
              <h1>Donate</h1>
              <p>
                The Aura Network is a non-profit project that has received
                support from the Science Walden Center that is financed by under
                the National Research Fund (NRF) of South Korea.
              </p>
              <p>YOUR SUPPORT IS APPRECIATED AND NEEDED!</p>
              <p>
                The Aura Network App evolved thanks to generous grants from
                National Research Fund (NRF) of Korea arranged by the Korean
                Pavilion Group with the Science Walden Center. These time-bound
                development and collaboration phases are now slowly coming to an
                end.
              </p>
              <p>
                As the Aura Network team and its administration is a voluntary
                service group and there are no fees involved for any of the
                users of the app, costs for web services, servers, and
                maintenance will have to be met. Contributions are welcome from
                anyone, anywhere.
              </p>
              <p>
                Kindly direct your contribution through any of the Auroville
                portals specifying the <strong>"Aura Network Platform".</strong>
              </p>
              <p>
                This Auroville webpage gives the technical details:{' '}
                <Link className="alt" to="https://auroville.org/contents/2835">
                  https://auroville.org/contents/2835.
                </Link>
              </p>
              <p>
                Any members of the Auroville Financial Service or Aurocard
                holders may give directly to the FS account number: 252011
              </p>
              <p>
                <strong>THANK YOU!</strong>
              </p>
              <br />
            </Col>
            <Col xs={24} sm={24} md={8} lg={8} xl={8} xxl={8}>
              <aside
                style={{
                  borderTop: '2px solid var(--green2)',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                  marginLeft: '1.1rem',
                  borderBottomLeftRadius: '0.55rem',
                  borderBottomRightRadius: '0.55rem',
                  overflow: 'hidden',
                }}
                className="responsive-aside"
              >
                <h3>Meet us</h3>
                <p>
                  You can also write to us at{' '}
                  <Link to="mailto:admin@auranetwork.app">
                    admin@auranetwork.app
                  </Link>{' '}
                  and request to meet us in person. The Aura Project is a
                  dynamic project and we keep on evolving and adapting based on
                  inputs we recieve from the community.
                </p>
              </aside>
              <aside
                style={{
                  borderTop: '2px solid var(--green2)',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                  marginLeft: '1.1rem',
                  marginTop: '1.1rem',
                  borderBottomLeftRadius: '0.55rem',
                  borderBottomRightRadius: '0.55rem',
                  overflow: 'hidden',
                }}
                className="responsive-aside"
              >
                <h3>Other useful links</h3>
                <p>
                  <Link className="alt" to="/tutorial/introduction">
                    Tutorial&nbsp;<span className="hand">☞</span>
                  </Link>
                  <br />
                  <Link className="alt" to="/report-a-bug">
                    Report a bug&nbsp;<span className="hand">☞</span>
                  </Link>
                  <br />
                  <Link className="alt" to="/community-feedback">
                    Community feedback&nbsp;<span className="hand">☞</span>
                  </Link>
                  <br />
                  <Link className="alt" to="/give-feedback">
                    Give us feedback&nbsp;<span className="hand">☞</span>
                  </Link>
                </p>
              </aside>
              <aside
                style={{
                  borderTop: '2px solid var(--green2)',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                  marginLeft: '1.1rem',
                  marginTop: '1.1rem',
                  borderBottomLeftRadius: '0.55rem',
                  borderBottomRightRadius: '0.55rem',
                  overflow: 'hidden',
                }}
                className="responsive-aside"
              >
                <h3>Learn with us</h3>
                <p>
                  <Link className="alt" to="/apprentice-or-intern">
                    Apprentice or intern&nbsp;<span className="hand">☞</span>
                  </Link>
                  <br />
                  <Link className="alt" to="/learn">
                    Learn&nbsp;<span className="hand">☞</span>
                  </Link>
                  <br />
                  <Link className="alt" to="https://book.auranetwork.app/en">
                    Web book&nbsp;<span className="hand">☞</span>
                  </Link>
                </p>
              </aside>
              <aside
                style={{
                  borderTop: '2px solid var(--green2)',
                  padding: '1.1rem',
                  background: 'var(--gray2)',
                  marginLeft: '1.1rem',
                  marginTop: '1.1rem',
                  borderBottomLeftRadius: '0.55rem',
                  borderBottomRightRadius: '0.55rem',
                  overflow: 'hidden',
                }}
                className="responsive-aside"
              >
                <h3>Support us</h3>
                <p>
                  Please consider donating so we can give shape to the Aura
                  Project for Auroville and beyond.
                </p>
                <p>
                  <Link className="alt" to="/donate">
                    Donate page&nbsp;<span className="hand">☞</span>
                  </Link>
                </p>
              </aside>
            </Col>
          </Row>
        </div>
      </StandardPageWrapper>
    )
  }
}

// ----------------------------------------------------------------------------
// ---------------------------------------------------------------------- State
// ----------------------------------------------------------------------------
const withState = connect(
  (state) => ({
    shoppingCartState: state.shoppingCartState,
  }),
  (dispatch) => ({
    pushObjectIntoShoppingCart(payload) {
      dispatch(pushObjectIntoShoppingCart(payload))
    },
    popObjectFromShoppingCart(payload) {
      dispatch(popObjectFromShoppingCart(payload))
    },
    pushNotice(payload) {
      dispatch(payload(payload))
    },
    increaseCountOfObjectInShoppingCart(payload) {
      dispatch(increaseCountOfObjectInShoppingCart(payload))
    },
    decreaseCountOfObjectInShoppingCart(payload) {
      dispatch(decreaseCountOfObjectInShoppingCart(payload))
    },
    resetShoppingCart(payload) {
      dispatch(resetShoppingCart(payload))
    },
    setForwardData(payload) {
      dispatch(setForwardData(payload))
    },
  })
)

// ----------------------------------------------------------------------------
// -------------------------------------------------------------------- Compose
// ----------------------------------------------------------------------------
/** Compose ala FP style */
const ComposedPage = compose(
  withState // Add state
)(Page)

// ----------------------------------------------------------------------------
// --------------------------------------------------------------------- Export
// ----------------------------------------------------------------------------
export default ComposedPage
